/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

const addScript = (url, id) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  if (id) {
    script.id = id;
  }
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  if (typeof window !== 'undefined') {
    window.onload = () => {
      addScript('https://assets.calendly.com/assets/external/widget.js');
      addScript(
        'https://static.zdassets.com/ekr/snippet.js?key=7e8b1837-82fb-48e0-8a47-10abd6164f25',
        'ze-snippet'
      );
    };
  }
};
