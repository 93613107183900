exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-chi-sono-js": () => import("./../../../src/pages/chi-sono.js" /* webpackChunkName: "component---src-pages-chi-sono-js" */),
  "component---src-pages-come-posso-aiutarti-js": () => import("./../../../src/pages/come-posso-aiutarti.js" /* webpackChunkName: "component---src-pages-come-posso-aiutarti-js" */),
  "component---src-pages-confirmation-js": () => import("./../../../src/pages/confirmation.js" /* webpackChunkName: "component---src-pages-confirmation-js" */),
  "component---src-pages-consulenza-gratuita-js": () => import("./../../../src/pages/consulenza-gratuita.js" /* webpackChunkName: "component---src-pages-consulenza-gratuita-js" */),
  "component---src-pages-contattaci-js": () => import("./../../../src/pages/contattaci.js" /* webpackChunkName: "component---src-pages-contattaci-js" */),
  "component---src-pages-contattaci-success-js": () => import("./../../../src/pages/contattaci-success.js" /* webpackChunkName: "component---src-pages-contattaci-success-js" */),
  "component---src-pages-eventi-speciali-js": () => import("./../../../src/pages/eventi-speciali.js" /* webpackChunkName: "component---src-pages-eventi-speciali-js" */),
  "component---src-pages-famiglia-sempre-js": () => import("./../../../src/pages/famiglia-sempre.js" /* webpackChunkName: "component---src-pages-famiglia-sempre-js" */),
  "component---src-pages-faq-pilates-js": () => import("./../../../src/pages/faq-pilates.js" /* webpackChunkName: "component---src-pages-faq-pilates-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-lezioni-js": () => import("./../../../src/pages/lezioni.js" /* webpackChunkName: "component---src-pages-lezioni-js" */),
  "component---src-pages-mappa-del-sito-js": () => import("./../../../src/pages/mappa-del-sito.js" /* webpackChunkName: "component---src-pages-mappa-del-sito-js" */),
  "component---src-templates-post-template-js": () => import("./../../../src/templates/post-template.js" /* webpackChunkName: "component---src-templates-post-template-js" */)
}

